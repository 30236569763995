@import "~bootstrap/dist/css/bootstrap.css";
@import "_variables";

// @import './_mixins';
/*#region Override background color theme */

body {
  padding: 0 0px 0px 0px;
  margin: 0px;
  font: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: ghostwhite;
  background: $background-color;
}

.mat-toolbar {
  background: $background-color;
  color: $font-color-text;
}
.mat-select-value {
  color: $font-color-text;
}

.mat-form-field {
  background: $background-color;
  color: $font-color-text;
}
/*For Bootstrap 3*/
.nav,
.pagination,
.carousel,
.panel-title a,
.card-title a {
  cursor: pointer;
}

.card-title {
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 4px;
  font-size: 1.4em;
  font-weight: 800;
}

h2 {
  font-size: 1.2em;
  color: $panel-default-heading-background;
}

/*#endregion */

/* #region Main Styles */

.ng-cloak {
  display: none !important;
}

select > option {
  color: black;
}

// input.form-control,
// select.form-control,
// textarea.form-control {
//     /*width: 205px;*/
//     width: 60%;
//     display: inline;
//     display: block;
// }

.widget .padd {
  /*margin-bottom: 0;*/
  /*padding: 1em 0;*/
  height: 12em;
}

.widget .padd.padd-tight {
  padding: 6px;
}

.list-flow.list-flow-tight {
  width: 90px;
}

.map {
  height: 228px;
}

.btn > i {
  margin-right: 8px;
}

.btn {
  margin-right: 4px;
  margin-left: 4px;
}

.btn.btn-notext > i {
  margin-right: 0;
}

.btn-group {
  margin: 0.2em;
}

small {
  font-size: 14px;
}

.text-subtle {
  color: #b7b7b7;
}

.page-title-subtle {
  color: white;
}

.page-title {
  color: #eee;
  margin-right: 12px;
}

.right {
  clear: right;
  float: right;
}

.widget-content .padd:hover {
  cursor: pointer;
  color: white;
  background-color: #52b9e9;
}

.widget-content .padd:hover * {
  color: white;
}

.list-flow {
  margin: 6px;
  float: left;
  width: 110px;
  height: 120px;
  border-bottom: solid 0 transparent;
  padding: 1.5% 0.5%;
}

.name-stack h5 {
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 18px !important;
}

.search-query {
  margin: 2px auto 8px auto !important;
}

.fa-search {
  color: #ffffff;
}

.form-search {
  min-height: 2em;
}

// .input-group[class*="col-"] {
//     width: 80%;
//     margin: 0 1.5em;}

.user .user-pic {
  display: block;
  margin: auto;
}

.user .user-details {
  margin-left: 100px;
  margin-right: 10px;
}

.widget-content {
  padding: 1em;
  margin: 0;
}

.stacked {
  float: none !important;
  display: block;
  margin: auto;
}

.navbar .navbar-brand {
  /*background: url(/content/images/icon.png) no-repeat left center !important;*/
  margin: 0 1em;
}
.panel-default .panel-heading,
.card {
  background-color: $panel-default-heading-background;
  color: white;
}

.panel,
.card {
  box-shadow: 0px 1px 4px $app-bar-background;
  border: none;
  border-radius: 2px;
  background-color: $card-background-color;
  margin-bottom: 8px;
}

.panel-body,
.card-block {
  background-color: $card-background-color;
  color: $color;
  padding-top: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.25rem;
}

.today-datas li {
  padding: 20px 14px;
}

/*#region Splash */
#splash-page {
  z-index: 99999 !important;
}

#splash-page .bar {
  width: 100%;
}

.page-splash {
  z-index: 99999 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.9;
  pointer-events: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.page-splash-message {
  text-align: center;
  margin: 20% auto 0 auto;
  font-size: 400%;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  -webkit-text-shadow: 2px 2px #000000;
  text-shadow: 2px 2px #000000;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  text-decoration: none;
  color: #f58a00;
  padding: 0;
}

.page-splash-message.page-splash-message-subtle {
  margin: 30% auto 0 auto;
  font-size: 200%;
}

.fa-asterisk.fa-asterisk-large {
  font-size: 180%;
  vertical-align: middle;
  color: #f58a00;
}

.fa-asterisk.fa-asterisk-alert {
  color: #f58a00;
}

.fa-asterisk-inline {
  padding: 0 4px 0 0;
}

.progress,
.page-progress-bar {
  margin: 30px 10% !important;
}

.spinner {
  margin: 20% auto 0 auto;
  left: auto;
  top: auto !important;
}
/*#endregion*/

table th > a {
  font-weight: bold;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  cursor: pointer;
}

.table {
  // background-color:$table-body-color;
  color: $table-font-color;
  border-radius: 5px;
}

.table > thead {
  background-color: $panel-default-heading-background;
  color: $table-font-color;
}

.table > tbody {
  background-color: $table-body-color;
  color: $table-font-color;
}

.table-striped {
  background-color: blue;
}
.widget-content.referrer {
  border-width: 0;
}

a {
  cursor: pointer;
}

/* #endregion */

/*#region wrapper for angular ng-include and ng-view animations*/
.view-container {
  position: relative;
  overflow: hidden;
}
/*#endregion */

/*#region Angular ng-include, ng-view, ng-repeat shuffle animations*/

.shuffle-animation.ng-enter,
.shuffle-animation.ng-leave {
  position: relative;
}

.shuffle-animation.ng-enter {
  -moz-transition: ease-out all 0.3s 0.4s;
  -o-transition: ease-out all 0.3s 0.4s;
  -webkit-transition: ease-out all 0.3s 0.4s;
  transition: ease-out all 0.3s 0.4s;
  left: 2em;
  opacity: 0;
}

.shuffle-animation.ng-enter.ng-enter-active {
  left: 0;
  opacity: 1;
}

.shuffle-animation.ng-leave {
  -moz-transition: 0.3s ease-out all;
  -o-transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
  left: 0;
  opacity: 1;
}

.shuffle-animation.ng-leave.ng-leave-active {
  left: 2em;
  opacity: 0;
}
/*#endregion*/

/*#region Angular ng-include, ng-view, ng-repeat fader animation */
.fader-animation.ng-enter,
.fader-animation.ng-leave,
.fader-animation.ng-move {
  position: relative;
}

.fader-animation.ng-enter,
.fader-animation.ng-leave {
  -webkit-transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  -moz-transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  -o-transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  opacity: 1;
}

.fader-animation.ng-enter,
.fader-animation.ng-leave.ng-leave-active {
  opacity: 0;
}

.fader-animation.ng-enter.ng-enter-active {
  opacity: 1;
}

.fader-animation.ng-move {
  opacity: 0.5;
}
.fader-animation.ng-move.ng-move-active {
  opacity: 1;
}

/*#endregion*/

/*#region Angular ng-show dissolve animation */
.dissolve-animation.ng-hide-remove {
  display: inline !important;
}

.dissolve-animation.ng-hide-remove,
.dissolve-animation.ng-hide-add {
  -webkit-transition: 0.8s linear all;
  -moz-transition: 0.8s linear all;
  -o-transition: 0.8s linear all;
  transition: 0.8s linear all;
}

.dissolve-animation.ng-hide-remove.ng-hide-remove-active,
.dissolve-animation.ng-hide-add {
  opacity: 1;
}

.dissolve-animation.ng-hide-add.ng-hide-add-active,
.dissolve-animation.ng-hide-remove {
  opacity: 0;
}
/*#endregion */

/*#region toastr */
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  margin: 4px auto;
}
/*#endregion */

/*#region Responsive */

@media (max-width: 979px) {
  body {
    font-size:14px;
  }

  .mat-body, .mat-typography {
    font-size:12px;
  }

  .sidebar-filler {
    width: 200px;
  }

  .nav-collapse {
    clear: none;
  }

  .nav-collapse .nav > li {
    float: left;
  }

  .navbar .btn-navbar {
    display: none;
  }

  .nav-collapse,
  .nav-collapse.collapse {
    height: inherit;
    overflow: inherit;
  }

  .page-splash-message {
    font-size: 300%;
  }

  .btn-group.pull-right {
    float: none !important;
    display: block;
  }
}

@media (max-width: 767px) {
  .sidebar-filler {
    display: none;
  }

  body {
    font-size:12px;
  }
  .mat-body, .mat-typography {
    font-size:12px;
  }

  .nav-collapse {
    clear: both;
  }

  .nav-collapse .nav > li {
    float: none;
  }

  .navbar .btn-navbar {
    display: block;
  }

  .nav-collapse,
  .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }

  .page-splash-message {
    font-size: 200%;
    margin: 40% auto 0 auto;
  }

  .page-splash-message.page-splash-message-subtle {
    font-size: 150%;
  }

  .sidebar .sidebar-inner {
    height: inherit;
  }
}

@media (max-width: 320px) {
  .today-datas li {
    padding: 1em 0.5em;
    line-height: 1em;
  }

  body {
    font-size:8px;
  }

  .today-datas li i {
    font-size: 2em;
  }

  .today-datas li .datas-text span {
    font-size: 1em;
  }

  .btn {
    margin: 0.2em;
    width: 7em;
  }

  .btn-group > .btn {
    display: block;
    width: 7em;
  }

  .btn-group.pull-right {
    margin: 0.2em 0;
  }

  // input,
  // select {
  //     width: 85% !important;
  // }

  textarea {
    width: 80%;
  }

  .img-polaroid.user-pic {
    width: 5em;
  }

  .user .user-details {
    margin-left: 7em;
    margin-right: 0.5em;
  }

  h3 {
    font-size: 1.5em !important;
    line-height: 1.2em !important;
  }
}

.mat-tooltip.tooltip {
  font-size: 1em !important;
}
/*#endregion */
