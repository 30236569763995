$sidebar-background-color: #232323;
$brand-color: #c2450b; //#ff5600
$sidebar-color: orange;
$background-color: black;
$sidebar-pill-background: orange;
$panel-default-heading-background: #000000;
$basketball-color: #b86850;

$table-body-color: #424242;
$table-font-color: #fce9cd;
$navbar-background-color: #212121;

$navbar-color: lighten($brand-color, 30%);
$sidebar-menu: lighten( black, 12%);
$card-background-color: darken(#3f3f3f, 10%);
$card-background-color-shadow: darken(#3f3f3f, 40%);
$card-color: lighten(#d17b00, 20%);
$status-bar-background: #000000;
$app-bar-background: #212121;
$color: #d17b00;
$card-sub-title-color: #3a5794;
$card-highlight: lighten($brand-color, 10%);

$border-radius: 10px;
$font-color-text: rgb(238, 231, 231);
$field-label:rgb(245, 245, 245);

$dialog-background-color: white;
$dialog-color: black;

/* font sizes */
$table-font-size: 1em;
$heading-font-size: 1.5em;
$card-body: 1.1em;
$wrapper-font-size: 1em;
$card-title-font-size: 1.8em;
$button-font-size: 1.4em;

